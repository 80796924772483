// Fonts
@font-face {
  font-family: HelveticaNeueLTPro;
  font-weight: 400;
  src: url('./_assets/fonts/HelveticaNeueLTPro-Lt.otf') format('opentype');
}
@font-face {
  font-family: HelveticaNeueLTPro-Condensed;
  font-weight: 400;
  src: url('./_assets/fonts/HelveticaNeueLTPro-LtCn.otf') format('opentype');
}

@font-face {
  font-family: HelveticaNeueLTPro;
  font-weight: 500;
  src: url('./_assets/fonts/HelveticaNeueLTPro-Roman.otf') format('opentype');
}
@font-face {
  font-family: HelveticaNeueLTPro;
  font-weight: 800;
  src: url('./_assets/fonts/HelveticaNeueLTPro-Bd.otf') format('opentype');
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 500;
  background-color: #f5f5f5;
  font-family: 'HelveticaNeueLTPro', 'Arial', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.snackbar-container {
  & button {
    color: #3f51b5;
  }
}

.snackbar-error {
  & p {
    color: lighten(#ff1654, 45%) !important;
  }

  & button {
    color: lighten(#ff1654, 5%) !important;
  }
}
